import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { Advantages } from "../components/Advantages";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const pageData = {
  header: "Kasy fiskalne",
  mainTitle: "Czas na",
  subtitle: "Kasy fiskalne online",
  mainTitle2: "Wybierz swoją",
  subtitle2: "Kasę fiskalną Online",
  paragraph:
    "To w pełni samodzielne urządzenia fiskalne: małe, średnie i systemowe. Funkcjonujące w przestrzeni biurowej oraz mobilnie",
  box: (
    <>
      <div className="qual-sign-box-element">
        <div className="imgBox">
          {" "}
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/kasa_1.png"
          />
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/mobile-online-logo.png"
          />
        </div>

        <span>
          Najmniejsza kasa fiskalna ONLINE, znakomite, mobilne rozwiązanie dla
          m.in. mechaników samochodowych i wulkanizatorów
        </span>
        <h4>Najważniejsze cechy:</h4>
        <ul>
          <li>
            <span>NIP nabywcy na paragonie</span>
          </li>
          <li>
            <span>Obsługa terminala płatniczego</span>
          </li>
          <li>
            <span>Grafika na paragonach</span>
          </li>
          <li>
            <span>
              Może wydrukować <b> 8000 paragońów</b> bez dodatkowego zasilania
            </span>
          </li>
          <li>
            <span>WIFI</span>
          </li>
          <li>
            <span>Modern GPRS (+karta SIM)</span>
          </li>
          <li>
            <span>Ładowanie przez port USB</span>
          </li>
          <li>
            <span>Eurofiskalizacja</span>
          </li>
        </ul>
        <a
          href="https://drive.google.com/file/d/1-pL-fItptrcSeZ7JuvaUjf8bYy4C8woq/view?usp=drive_link"
          target="_blank"
          rel="noreferrer noopener "
        >
          <button className="btn-red">Pobierz broszurę</button>
        </a>
      </div>
      <div className="qual-sign-box-element">
        <div className="imgBox">
          {" "}
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/kasa_2.png"
          />
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/ergo-online-logo.png"
          />
        </div>

        <span>
          Kasa Ergo ONLINE świetnie sprawdzi się w sklpeie, jak i na stoisku
          targowym. Dodkasy można podłączyć m.in.: wagę, skaner, kodów
          kreskowych czy terminal płatniczy
        </span>
        <h4>Najważniejsze cechy:</h4>
        <ul>
          <li>
            <span>NIP nabywcy na paragonie</span>
          </li>
          <li>
            <span>Obsługa terminala płatniczego</span>
          </li>
          <li>
            <span>Grafika na paragonach</span>
          </li>
          <li>
            <span>Graficzne wyświetlacze</span>
          </li>
          <li>
            <span>Nowoczesna kasowaga</span>
          </li>
          <li>
            <span>WiFi (opcja)</span>
          </li>
          <li>
            <span>Modern GPRS (opcja)</span>
          </li>
          <li>
            <span>Eurofiskalizacja</span>
          </li>
        </ul>
        <a
          href="https://drive.google.com/file/d/1bP2qaHBnMtSNNMMAJig6fJf1i8IRrRrc/view?usp=drive_link"
          target="_blank"
          rel="noreferrer noopener "
        >
          <button className="btn-red">Pobierz broszurę</button>
        </a>
      </div>
      <div className="qual-sign-box-element">
        <div className="imgBox">
          {" "}
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/kasa_3.png"
          />
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/revo-online-logo.png"
          />
        </div>
        <span>
          Kasę Revo najczęściej wybierają przedsiębiorcy, którzy stawiają na
          wielofunkcyjne urządzenia zamknięte w zwartej obudowie
        </span>
        <h4>Najważniejsze cechy:</h4>
        <ul>
          <li>
            <span>NIP nabywcy na paragonie</span>
          </li>
          <li>
            <span>Obsługa terminala płatniczego</span>
          </li>
          <li>
            <span>Grafika na paragonach</span>
          </li>
          <li>
            <span>Graficzne wyświetlacze</span>
          </li>
          <li>
            <span>Wygodna i wytrzymała klawiatura</span>
          </li>
          <li>
            <span>WiFi</span>
          </li>
          <li>
            <span>Modern GPRS (opcja)</span>
          </li>
          <li>
            <span>Zintegrowana waga Fawag DS1</span>
          </li>
        </ul>
        <a
          href="
          https://drive.google.com/file/d/1tv2Y_iBNUeiBGNSC9q1d28scofafe3N9/view?usp=drive_link"
          target="_blank"
          rel="noreferrer noopener "
        >
          <button className="btn-red">Pobierz broszurę</button>
        </a>
      </div>
      <div className="qual-sign-box-element">
        <div className="imgBox">
          {" "}
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/kasa_4.png"
          />
          <StaticImage
            className="img"
            src="../assets/images/registersOffer/bingo-online-logo.png"
          />
        </div>

        <span>
          Najmniejsza kasa fiskalna ONLINE, znakomite, mobilne rozwiązanie dla
          m. in. mechaników samochodowych i wulkanizatorów
        </span>
        <h4>Najważniejsze cechy:</h4>
        <ul>
          <li>
            <span>NIP nabywcy na paragonie</span>
          </li>
          <li>
            <span>Obsługa terminala płatniczego</span>
          </li>
          <li>
            <span>Grafika na paragonach</span>
          </li>
          <li>
            <span>Graficzne wyświetlacze</span>
          </li>
          <li>
            <span>Wygodna i wytrzymała klawiatura</span>
          </li>
          <li>
            <span>WiFi (opcja)</span>
          </li>
          <li>
            <span>Modern 3G</span>
          </li>
          <li>
            <span>Zintegrowana waga Fawag DS1</span>
          </li>
        </ul>
        <a
          href="
          https://drive.google.com/file/d/1CLaJ1bj-DoGxKfbs6jThw0YIOEfGPeVN/view?usp=drive_link"
          target="_blank"
          rel="noreferrer noopener "
        >
          <button className="btn-red">Pobierz broszurę</button>
        </a>
      </div>
    </>
  ),
};

const advElements = [
  {
    contentTitle: "Automatyzacja",
    contentTxt:
      "Kasy fiskalne online łączą się automatycznie z CRK, przesyłając dane fiskalne w czasie rzeczywistym",
    imgSrc: require("../assets/images/registersOffer/ikony_kasy_fiskalne_1.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Paragony",
    contentTxt:
      "Mamy możliwość wydruku na papierze lub przesłania w formie elektronicznej, na przykładza pośrednictwem poczty e-mail",
    imgSrc: require("../assets/images/registersOffer/ikony_kasy_fiskalne_5.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Aktualizacje",
    contentTxt:
      "Kasa nie wymaga wyłączenia z użytku podczas aktualizacji - teraz wszystko odbywa się zdalnie",
    imgSrc: require("../assets/images/registersOffer/ikony_kasy_fiskalne_2.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Oszczędność",
    contentTxt:
      "Ograniczenie zużycia papieru dzięki przechowywaniu kopii paragonów, raportów i faktur w pamięci wewnętrznej",
    imgSrc: require("../assets/images/registersOffer/ikony_kasy_fiskalne_3.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Zgodność",
    contentTxt:
      "Integrują się z innymi urządzeniami w większych infrastrukturach, np. z systemami obsługi restauracji lub kawiarni",
    imgSrc: require("../assets/images/registersOffer/ikony_kasy_fiskalne_4.svg")
      .default,
    alt: "Opis obrazka",
  },
];

const QualSignPage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`sign-page-info ${active} registers`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="sign-page registers-page">
          <div id="info" className="anchor"></div>
          <div className="aboutUs-container">
            <div className="imgContainer">
              <StaticImage src="../assets/images/registersOffer/registers1.jpg" />
              <div className="square"></div>
            </div>

            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Czym są <br /> <strong>kasy fiskalne online?</strong>
                </h2>
              </div>
              <p className="headerTxt">
                <b>Kasy online</b> to specjalne urządzenia fiskalne, które
                nawiązują stały kontakt z Centralnym Repozytorium Kas (CRK),
                będącym prowadzonym przez{" "}
                <b>Szefa Krajowej Administracji Skarbowej.</b> Urządzenia te
                regularnie przesyłają wymagane informacje zgodnie z
                harmonogramem określonym przez Repozytorium.
              </p>
              <p className="headerTxt">
                CRK jest również odpowiedzialne za nadawanie urządzeniom online{" "}
                <b>unikatowych numerów ewidencyjnych</b> podczas procesu
                fiskalizacji. Kasy online automatycznie łączą się z Repozytorium
                co dwie godziny, chociaż warto zaznaczyć, że Repozytorium ma
                zdolność do dostosowania tego harmonogramu według potrzeb.
              </p>
              <p className="headerTxt">
                {" "}
                Repozytorium, pełni funkcję zbierania różnorodnych danych. Do
                repozytorium trafiają informacje z{" "}
                <b>
                  raportów dobowych, paragonów fiskalnych, faktur, paragonów i
                  faktur anulowanych,
                </b>{" "}
                a także <b>dokumentów niefiskalnych.</b> Dodatkowo, Repozytorium
                gromadzi informacje o innych zdarzeniach zarejestrowanych na
                urządzeniach, takie jak obowiązkowe przeglądy okresowe.
              </p>
              <Link to="#devices">
                <button className="btn-red right">Sprawdź urządzenia</button>
              </Link>
            </div>
          </div>
          <Advantages
            headerSubtitle={
              <>
                Kasy fiskalne online <br /> <strong>zalety urządzeń</strong>
              </>
            }
            // headerTxt={"Lorem ipsum"}
            advantageElements={advElements}
          />
          <div className="aboutUs-container onlyTxt">
            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Wybrane funkcje <br /> <strong>kas fiskalnych online</strong>
                </h2>
              </div>
              <div className="txt-container-wrapper light">
                <p>
                  <b></b> Użytkownik kasy ONLINE musi zapewnić dostęp do
                  internetu, czyli odpowiednią infrastrukturę, dzięki której
                  urządzenie będzie się łączyć z Repozytorium.
                </p>
                <p>
                  <b></b> Wymagania, co do sieci internetowej są minimalne.
                  Użytkownik musi udostępnić połączenia do adresów internetowych
                  Repozytorium wskazanych przez Ministerstwo Finansów.
                </p>
                <p>
                  <b></b> Z powodu np. braku dostępu do internetu w miejscu
                  prowadzenia działalności gospodarczej, podatnik może uzyskać
                  zgodę Urzędu Skarbowego na łączenie się z CRK w innych,
                  ustalonych z US terminach.
                </p>
                <p>
                  <b></b> Połączenie urządzenia ONLINE z CRK odbywa się bez
                  wiedzy użytkownika, ale każda kasa ONLINE posiada funkcję
                  komunikacji na żądanie - „połącz z Repozytorium”.
                </p>
                <p>
                  <b></b> Podatnik jest zobowiązany do poddawania urządzeń
                  ONLINE obowiązkowym przeglądom technicznym, nie rzadziej, niż
                  co 24 miesiące, przy czym datę pierwszego przeglądu
                  technicznego liczy się od daty fiskalizacji urządzenia.
                </p>
              </div>
            </div>
            <div className="aboutUs txt">
              <div className="txt-container-wrapper light">
                <p>
                  <b> </b> Kasy ONLINE mają funkcję przypomnienia o przeglądzie
                  technicznym, a wykonanie przeglądu jest rejestrowane przez
                  serwis na urządzeniu.
                </p>
                <p>
                  <b></b> Wykonując raport dobowy na kasie ONLINE podatnik może
                  zrezygnować z jego wydruku (forma papierowa), wystarczy że
                  raport dobowy zostanie wykonany jako zapis elektroniczny.
                </p>
                <p>
                  <b></b> Po każdym raporcie dobowym urządzenie łączy się z
                  serwerami czasu urzędowego i synchronizuje ustawienia swojego
                  zegara RTC, dzięki czemu ma poprawne ustawienia czasu i może
                  samo aktualizować czas letni i zimowy.
                </p>
                <p>
                  <b></b> Raport miesięczny może być wykonywany w formie
                  skróconej, przez co jest zdecydowanie mniej obszerny niż w
                  urządzeniach tradycyjnych. Taki raport za dany miesiąc
                  wykonuje się do 25 dnia następnego miesiąca.
                </p>
                <p>
                  <b></b> Integracja kasy fiskalnej z terminalem płatniczym
                  pozwala na automatyzację przyjmowania płatności
                  elektronicznych. Bezpośrednio po paragonie terminal otrzymuje
                  informacje o transakcji, autoryzuje ją, a potwierdzenie
                  dokonania płatności kartą.
                </p>
              </div>
            </div>
          </div>
          <div id="devices" className="anchor"></div>
          <div className={`sign-page-info ${active} qual-sign`}>
            <PageInfo
              mainTitle={pageData.mainTitle2}
              subtitle={pageData.subtitle2}
              paragraph={pageData.paragraph}
              box={pageData.box}
            />
          </div>
          <div className="aboutUs-container onlyTxt">
            <div className="aboutUs txt first">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Terminal płatniczy <br /> <strong>NETPLAY</strong>
                </h2>
              </div>
              <p className="headerTxt">
                Netpay to terminal płatniczy, który w połączeniu z kasą fiskalną
                ONLINE Postnet lub Fawag pozwala na realizowanie płatności
                bezgotówkowych. <br />
                Udostępnia Internet, umożliwiając wygodną łączność z Centralnym
                Repozytorium Kas będąc przy tym intuicyjny i przyjazny w
                obsłudze.
              </p>
              <div className="txt-container-wrapper">
                <p>
                  <b></b> Dożywotnia gwarancja
                </p>
                <span>
                  Netpay objęty jest dożywotnią gwarancją i kompleksowym
                  serwisem
                </span>
              </div>
              <div className="txt-container-wrapper">
                <p>
                  <b></b> Polska bezgotówkowa
                </p>
                <span>Netpay jest dostępny w ramach programu</span>
              </div>
              <div className="txt-container-wrapper">
                <p>
                  <b></b> e-Service
                </p>
                <span>
                  Płatności realizowane za pomocą Netpay obsługuje Centrum
                  Elektronicznych Usług Płatniczych e-Service
                </span>
              </div>
              <div className="txt-container-wrapper">
                <p>
                  <b></b> Płatności bezgotówkowe
                </p>
                <span>
                  Obsługa płatności bezgotówkowych wykonywanych kartami
                  magnetycznymi, chipowymi i zbliżeniowymi (VISA, MasterCard,
                  Diners Club, Discover). Netpay obsługuje również płatności
                  realizowane za pomocą : BLIK, Google Pay i Apple Pay
                </span>
              </div>
            </div>
            <div className="imgContainer bottom">
              <StaticImage src="../assets/images/registersOffer/registers2.png" />
              <div className="square"></div>
            </div>
          </div>
        </section>
        <div className="footer-container-wrapper-form">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
            <div className="form-txt">
              <h2>Dział Obsługi Klienta</h2>
              <p>ds. Sprzedaży Kas Fiskalnych</p>
              <br />
              <p>
                Tel: <b>+48 83 344 70 02</b>
              </p>
              <p>
                Kom: <b>+48 726 337 000</b>
              </p>
              <br />
              <p>
                e-mail: <b>piotr.walecki@jbmultimedia.pl</b>
              </p>
            </div>
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => <Seo title="Kasy fiskalne" />;
export default QualSignPage;
